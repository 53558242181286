<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <span class="brand-logo">
            <!-- <b-img :src="appLogoImage" width="180px" alt="logo" />
             -->
            <b-img
              v-if="skin === 'light'"
              :src="secusyLightLogo"
              width="180px"
              alt="logo"
            />
            <b-img v-else :src="secusyDarkLogo" alt="logo" width="180px" />
          </span>
        </b-link>

        <b-card-title class="mb-1"> Reset Password 🔒 </b-card-title>
        <b-card-text class="mb-2">
          Please provide your email address registered with us
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent
          >
            <!-- password -->
            <b-form-group label="Email" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="email"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="email"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              @click="validationForm"
            >
              <span v-if="loading">Please wait</span>
              <span v-else>Send Link</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    VuexyLogo,
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      required,
      email,
      // showOverlay: false,
    };
  },
  // computed: {
  //   imgUrl() {
  //     if (store.state.appConfig.layout.skin === "dark") {
  //       this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
  //       return this.sideImg;
  //     }
  //     return this.sideImg;
  //   },
  // },
  methods: {
    validationForm() {
      this.$refs.loginValidation
        .validate()
        .then((success) => {
          if (success) {
            const data = {
              email: this.email,
            };
            this.$store
              .dispatch("app/forgotPassword", data, { root: true })
              .then((res) => {
                console.log(res);
                this.$router.replace("/login");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                }
              })
              .finally(() => {
                // this.showOverlay = false;
              });
          }
        })
        .catch(() => {
          // this.showOverlay = false;
        });
    },
  },
  setup() {
    const { appName, secusyLightLogo, secusyDarkLogo } = $themeConfig.app;
    const { skin } = useAppConfig();
    return {
      // App Name
      appName,
      secusyLightLogo,
      secusyDarkLogo,
      skin,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
